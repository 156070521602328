import React from "react";
import { Destination, FormField } from "@prequel/react";
import ConstWrapper from "./ConstWrapper";
import InternalWrapper from "./InternalWrapper";
import SelectWrapper from "./SelectWrapper";
import InputWrapper from "./InputWrapper";
import TextAreaWrapper from "./TextAreaWrapper";
import RadioWrapper from "./RadioWrapper";
import { SourceFormField } from "../../store/sources/form";
import { Source } from "../../store/sources";

export type WrapperProps<
  F extends FormField | SourceFormField,
  C extends Destination | Source
> = {
  field: F;
  connection: C;
  setConnectionField: (
    key: keyof C,
    value: string | boolean | string[] | number
  ) => void;
  isEditing: boolean;
  disabled?: boolean;
};
const WrapperSelector = <
  F extends FormField | SourceFormField,
  C extends Destination | Source
>({
  field,
  connection,
  setConnectionField,
  disabled = false,
  isEditing,
}: WrapperProps<F, C>) => {
  if (field.const) {
    return (
      <ConstWrapper
        field={field}
        connection={connection}
        setConnectionField={setConnectionField}
        isEditing={isEditing}
      />
    );
  } else if (field.internal) {
    return (
      <InternalWrapper
        field={field}
        connection={connection}
        setConnectionField={setConnectionField}
        disabled={disabled}
        isEditing={isEditing}
      />
    );
  } else if (field.form_element === "select") {
    return (
      <SelectWrapper
        field={field as F & { form_element: "select" }}
        connection={connection}
        setConnectionField={setConnectionField}
        disabled={disabled}
        isEditing={isEditing}
      />
    );
  } else if (field.form_element === "input") {
    return (
      <InputWrapper
        field={field as F & { form_element: "input" }}
        connection={connection}
        setConnectionField={setConnectionField}
        disabled={disabled}
        isEditing={isEditing}
      />
    );
  } else if (field.form_element === "textarea") {
    return (
      <TextAreaWrapper
        field={field as F & { form_element: "textarea" }}
        connection={connection}
        setConnectionField={setConnectionField}
        disabled={disabled}
        isEditing={isEditing}
      />
    );
  } else if (field.form_element === "radio") {
    return (
      <RadioWrapper
        field={field as F & { form_element: "radio" }}
        connection={connection}
        setConnectionField={setConnectionField}
        disabled={disabled}
        isEditing={isEditing}
      />
    );
  } else {
    return null;
  }
};

export default WrapperSelector;
