import { Source } from ".";
import {
  Section,
  FormSection as DestinationFormSection,
  FormField as DestinationFormField,
  FormNode as DestinationFormNode,
  EnumObject,
} from "@prequel/react";

type SourceFormNode = Omit<DestinationFormNode, "name" | "children"> & {
  name: keyof Source;
  children: SourceFormNode[] | null;
};
export type SourceForm = {
  sections: Section[];
  form: SourceFormNode;
};

export type SourceFormField = Omit<
  DestinationFormField,
  "name" | "prepared_destination_path"
> & {
  name: keyof Source;
  readonly prepared_source_path: string;
} & (
    | {
        form_element: "input";
        input_type: "text" | "password" | "number";
      }
    | {
        form_element: "textarea";
      }
    | {
        form_element: "radio";
        enum: EnumObject[];
      }
    | {
        form_element: "select";
        enum?: EnumObject[];
      }
    | {
        form_element: "div";
      }
  );
export type SourceFormSection = Omit<DestinationFormSection, "fields"> & {
  fields: SourceFormField[];
};
export type Form = SourceFormSection[];

export const BUCKET_AUTH_FIELD_PARENT = {
  access_id: "aws_access_keys",
  secret_key: "aws_access_keys",
  secret: "gcs_hmac_keys",
  access_key: "gcs_hmac_keys",
};
const TOP_LEVEL_SOURCE_FIELDS = ["vendor", "name"];

export const composeForm: (
  s: Source,
  f: SourceForm,
  publicKey?: string
) => Form = (source, formResponse, publicKey) => {
  const { form, sections } = formResponse;
  const newFields = getFormFields([], form, source, publicKey);

  const newSections = sections.map((section) => ({
    ...section,
    fields: newFields.filter(({ section_id }) => section_id === section.id),
  }));

  const filteredSections = newSections.filter(
    ({ fields }) => fields.length > 0
  );

  return filteredSections;
};

// Recursively traverses and flattens tree of children
const getFormFields: (
  parentPath: string[],
  node: SourceFormNode,
  source: Source,
  publicKey: string | undefined
) => SourceFormField[] = (parentPath, node, source, publicKey) => {
  if (!node) {
    return [];
  }

  if (node.name === "ssh_public_key" && publicKey) {
    node = { ...node, const: publicKey };
  }

  // If it's a top-level field, we don't need to set up the parentPath
  if (!TOP_LEVEL_SOURCE_FIELDS.includes(node.name)) {
    if (node.parent === true) {
      // SSH Tunnel fields have parent set to true
      parentPath = parentPath.concat(["ssh_tunnel"]);
    } else if (node.parent === false) {
      // SSL Option fields have parent set to false
      parentPath = parentPath.concat(["ssl_options"]);
    } else {
      parentPath = parentPath.concat([node.parent.toString()]);
    }

    if (["bucket_name", "bucket_region"].includes(node.name)) {
      // These fields need an extra layer of nesting for the following vendors
      if (parentPath.includes("bigquery")) {
        parentPath = parentPath.concat(["bucket_gcs"]);
      } else if (parentPath.includes("redshift")) {
        parentPath = parentPath.concat(["bucket_s3"]);
      } else if (parentPath.includes("redshift_serverless")) {
        parentPath = parentPath.concat(["bucket_s3"]);
      } else if (parentPath.includes("athena")) {
        parentPath = parentPath.concat(["bucket_s3"]);
      }
    }

    if (
      (parentPath.includes("clickhouse") ||
        parentPath.includes("databricks")) &&
      BUCKET_AUTH_FIELD_PARENT[node.name]
    ) {
      // Bucket auth fields should be nested under bucket_auth.
      // Not all values in the form are represented with nesting so we need
      // to create that nesting here for Clickhouse and Databricks
      parentPath = parentPath.concat([BUCKET_AUTH_FIELD_PARENT[node.name]]);
    }
  }

  const currentPath = parentPath.concat([node.name]);
  const b = buildFormField(node, currentPath);
  const result: SourceFormField[] = b ? [b] : [];

  for (const child of node.children || []) {
    if (source[node.name] === child.parent) {
      result.push(...getFormFields(parentPath, child, source, publicKey));
    }
  }

  return result;
};

const buildFormField: (
  node: SourceFormNode,
  path: string[]
) => SourceFormField = (node, path) => {
  const baseResult = {
    prepared_source_path: deduplicate(path).join("."),
    name: node.name as keyof Source,
    required: node.required,
    type: node.type,
    label: node.attributes.label,
    placeholder: node.attributes.placeholder,
    description: node.attributes.description,
    internal: node.attributes.internal,
    const: node.const,
    section_id: node.attributes.section_id,
    input_type: node.attributes.input_type,
  };

  if (node.attributes.form_element === "input") {
    return {
      ...baseResult,
      form_element: "input",
      input_type: node.attributes.input_type,
    } as SourceFormField;
  } else if (node.attributes.form_element === "textarea") {
    return {
      ...baseResult,
      form_element: "textarea",
    } as SourceFormField;
  } else if (node.attributes.form_element === "radio") {
    return {
      ...baseResult,
      form_element: "radio",
      enum: node.enum,
    } as SourceFormField;
  } else if (node.attributes.form_element === "select") {
    return {
      ...baseResult,
      form_element: "select",
      enum: node.enum,
    } as SourceFormField;
  } else {
    return {
      ...baseResult,
      form_element: "div",
    } as SourceFormField;
  }
};

const deduplicate = (a: string[]) => {
  const seen = new Set();
  return a.filter((item) => {
    if (seen.has(item)) {
      return false;
    }
    seen.add(item);
    return true;
  });
};
