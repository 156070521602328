import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Button,
  ButtonStyle,
  Spinner,
} from "@prequel-internal/react-components";

import { useTypedDispatch, useTypedSelector } from "../../../store";
import {
  createSource,
  fetchSourceForm,
  fetchSources,
  fetchSSHPublicKey,
  selectSource,
  selectSourceForm,
  selectSourceSSHPublicKey,
  selectSourceTest,
  updateSource,
} from "../../../store/sources/sources.duck";
import {
  Source,
  SourceType,
  PreparedSource,
  prepareSourceWithForm,
  prepareSourceFromExisting,
  computeChangedFields,
} from "../../../store/sources";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import TestSourceConnection from "../TestSourceConnection";
import { fetchOrg, selectOrg } from "../../../store/org/org.duck";
import WrapperSelector from "../../WrapperSelector";
import {
  getDefaultAuthMethod,
  getDefaultBucketVendor,
  getDefaultMetastore,
} from "@prequel/react";
import { composeForm } from "../../../store/sources/form";

const SourceForm = () => {
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();
  const formRef = useRef<HTMLFormElement>(null);
  const { id } = useParams<{ id: string }>();
  const [source, setSource] = useState<Source>({
    type: SourceType.Source,
    name: "",
    vendor: "aurora_postgres",
  });

  const sourceToEdit = useTypedSelector((state) => selectSource(state, id));
  const isEditing = !!sourceToEdit; // Evaluates to true if sourceToEdit exists, otherwise evaluates to false
  const sourceTest = useTypedSelector(selectSourceTest);
  const org = useTypedSelector(selectOrg);
  const sourceForm = useTypedSelector(selectSourceForm);
  const sshPublicKey = useTypedSelector(selectSourceSSHPublicKey);

  const setSourceField = useCallback(
    (
      key: keyof Source,
      value: string | string[] | boolean | number | undefined
    ) => {
      setSource((currentSource) => ({
        ...currentSource,
        [key]: value,
      }));
    },
    [setSource]
  );

  const form = useMemo(() => {
    if (sourceForm) {
      return composeForm(
        source,
        sourceForm,
        source.ssh_public_key ?? sshPublicKey
      );
    }
  }, [source, sourceForm, sshPublicKey]);

  const preparedSource: PreparedSource = useMemo(
    () => prepareSourceWithForm(source, form),
    [source]
  );

  const selectedVendor = useMemo(() => {
    if (form) {
      const { fields } = form[0];
      if (fields[0].form_element === "select") {
        return fields[0].enum?.find(({ key }) => key === source.vendor);
      }
    }
  }, [form, source.vendor]);

  useEffect(() => {
    if (selectedVendor && !isEditing) {
      // only set defaults when creating new destination
      const defaultAuthMethod = getDefaultAuthMethod(
        selectedVendor.key.toString()
      );
      const defaultBucketVendor = getDefaultBucketVendor(
        selectedVendor.key.toString()
      );
      const defaultMetastore = getDefaultMetastore(
        selectedVendor.key.toString()
      );
      setSource((currentSource) => ({
        ...currentSource,
        auth_method: defaultAuthMethod,
        bucket_vendor: defaultBucketVendor,
        metastore: defaultMetastore,
      }));
    }
  }, [selectedVendor]);

  useEffect(() => {
    if (source.use_ssh_tunnel && !sshPublicKey && !source.ssh_public_key) {
      dispatch(fetchSSHPublicKey());
    }
  }, [dispatch, source.use_ssh_tunnel]);

  const validateForm = () =>
    formRef.current ? formRef.current.reportValidity() : false;

  useEffect(() => {
    if (!org) {
      dispatch(fetchOrg());
    }
    dispatch(fetchSources());
    dispatch(fetchSourceForm(org?.id));
  }, [dispatch, org]);

  useEffect(() => {
    if (form && sourceToEdit) {
      setSource(prepareSourceFromExisting(sourceToEdit));
    }
  }, [sourceToEdit]);

  const onSave = () => {
    if (isEditing) {
      const changed = computeChangedFields(sourceToEdit, preparedSource);
      dispatch(
        updateSource({
          sourceId: sourceToEdit.id,
          source: changed,
          redirect: () => navigate("/export/sources"),
        })
      );
    } else {
      dispatch(
        createSource({
          source: preparedSource,
          redirect: () => navigate("/export/sources"),
        })
      );
    }
  };

  // Intercept native form submission, prevent default, and run test
  // We use the default form submission event so that we can borrow the browsers built-in support for handling missing required fields
  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSave();
  };

  if (!form || (id && sourceToEdit === undefined)) {
    return <Spinner />;
  }

  if (id && sourceToEdit === null) {
    return <Navigate to="/export/sources" replace />;
  }

  return (
    <div className="pb-16">
      <form className="space-y-8" onSubmit={onSubmit} ref={formRef}>
        {form.map((section) => {
          return (
            <Fragment key={section.id}>
              <div key={section.id}>
                {section.id !== 1 && (
                  <>
                    <label
                      htmlFor="description"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {section.title}
                    </label>
                    {section.subtitle ? (
                      <div className="mt-1 mb-4">
                        <p className="mt-1 text-sm text-gray-500">
                          {section.subtitle}
                          {section.id === 3 && selectedVendor && (
                            <>
                              {" For assistance, "}
                              <a
                                href={selectedVendor.docs}
                                target="_blank"
                                rel="noreferrer"
                                className="font-medium text-primary-600 hover:text-primary-500"
                              >
                                view our documentation on{" "}
                                {selectedVendor.display}.
                              </a>
                            </>
                          )}
                        </p>
                      </div>
                    ) : (
                      <br />
                    )}
                  </>
                )}
                <div className="space-y-4">
                  {section.fields.map((field) => (
                    <div key={field.name}>
                      <WrapperSelector
                        field={field}
                        connection={source}
                        setConnectionField={setSourceField}
                        disabled={sourceTest?.status === "processing"}
                        isEditing={isEditing}
                      />
                    </div>
                  ))}
                </div>
              </div>
              <div className="w-full h-px bg-gray-200" /> {/* Divider  */}
            </Fragment>
          );
        })}
        <TestSourceConnection
          beforeSubmitTest={validateForm}
          preparedSource={preparedSource}
          existingSource={sourceToEdit}
        />
      </form>
      <div className="flex justify-end mt-8">
        {isEditing && (
          <Button
            className="mr-3"
            type={ButtonStyle.TERTIARY}
            onClick={() => navigate(-1)}
            text="Cancel"
          />
        )}
        <Button
          type={
            !(sourceTest.status === "success")
              ? ButtonStyle.TERTIARY
              : ButtonStyle.PRIMARY
          }
          disabled={!(sourceTest.status === "success")}
          onClick={onSave}
          text="Save Source"
        />
      </div>
    </div>
  );
};

export default SourceForm;
