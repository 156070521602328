import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  ButtonStyle,
  ConfirmModal,
  DescriptionList,
  Modal,
  Spinner,
} from "@prequel-internal/react-components";

import { useTypedDispatch, useTypedSelector } from "../../../store";
import {
  deleteSource,
  fetchSources,
  fetchSourceForm,
  selectSourceForm,
  selectSource,
  fetchSourceVendors,
  selectSourceVendors,
} from "../../../store/sources/sources.duck";
import VendorLogo from "../../../components/VendorLogo";
import { fetchOrg, selectOrg } from "../../../store/org/org.duck";
import Timestamp from "../../../components/Timestamp";
import {
  getDefaultSource,
  prepareSourceFromExisting,
} from "../../../store/sources";
import { composeForm } from "../../../store/sources/form";

const SourceDetails = () => {
  const { id } = useParams<{ id: string }>();
  const existingSource = useTypedSelector((state) => selectSource(state, id));
  const source = existingSource
    ? prepareSourceFromExisting(existingSource)
    : getDefaultSource();

  const org = useTypedSelector(selectOrg);
  const sourceForm = useTypedSelector(selectSourceForm);
  const sourceVendors = useTypedSelector(selectSourceVendors);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const navigate = useNavigate();
  const dispatch = useTypedDispatch();

  const sourceVendor = sourceVendors?.find(({ key }) => key === source?.vendor);

  const form = useMemo(() => {
    if (sourceForm) {
      return composeForm(source, sourceForm);
    }
  }, [source, sourceForm]);

  useEffect(() => {
    if (!org) {
      dispatch(fetchOrg());
    }
    dispatch(fetchSourceVendors());
    dispatch(fetchSourceForm(org?.id));
    dispatch(fetchSources());
  }, [dispatch, org]);

  const onDelete = () => setShowDeleteModal(true);

  const onConfirmDelete = () => {
    setShowDeleteModal(false);
    if (existingSource) {
      dispatch(
        deleteSource({
          sourceId: existingSource.id,
          redirect: () => navigate("/sources"),
        })
      );
    }
  };

  if (!source || !form) {
    return <Spinner />;
  }

  return (
    <>
      <Modal open={showDeleteModal} onClose={() => setShowDeleteModal(false)}>
        <ConfirmModal
          title="Delete source"
          message="Are you sure you want to delete this source? This action cannot be undone."
          onConfirm={onConfirmDelete}
          confirmButtonText="Delete"
          onCancel={() => setShowDeleteModal(false)}
        />
      </Modal>
      <div className="space-y-1">
        <h3 className="text-lg leading-6 font-medium text-gray-900">Details</h3>
      </div>
      <div className="mt-6">
        {form && source && (
          <DescriptionList>
            {sourceVendor && (
              <DescriptionList.Item
                label="Vendor"
                value={
                  <div className="flex items-center">
                    <VendorLogo logo_url={sourceVendor.icon_url ?? ""} />
                    {sourceVendor.display}
                  </div>
                }
              />
            )}
            {form.map((section) =>
              section.fields.map((field) => {
                if (
                  field.name === "password" ||
                  field.name === "service_account_key" ||
                  field.const
                ) {
                  // Skip constants and passwords, don't need rendering
                  return;
                }

                if (field.name === "use_ssh_tunnel") {
                  return (
                    <DescriptionList.Item
                      key={field.name}
                      label={"Use SSH Tunnel"}
                      value={source[field.name] ? "Yes" : "No"}
                    />
                  );
                }

                if (field.name === "disable_ssl") {
                  return (
                    <DescriptionList.Item
                      key={field.name}
                      label={field.label}
                      value={source[field.name] ? "Yes" : "No"}
                    />
                  );
                }

                return (
                  field.label && (
                    <DescriptionList.Item
                      key={field.name}
                      label={field.label}
                      value={source[field.name]}
                    />
                  )
                );
              })
            )}
            {existingSource?.created_at && (
              <DescriptionList.Item
                label="Created"
                value={<Timestamp timestamp={existingSource.created_at} />}
              />
            )}
            {existingSource?.updated_at && (
              <DescriptionList.Item
                label="Updated"
                value={<Timestamp timestamp={existingSource.updated_at} />}
              />
            )}
          </DescriptionList>
        )}
      </div>

      <div className="mt-10 divide-y divide-red-300">
        <div className="space-y-1">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Danger Zone
          </h3>
          <p className="max-w-2xl text-sm text-gray-500">
            Actions in this section should be considered carefully.
          </p>
        </div>
        <div className="mt-6">
          <DescriptionList type="danger">
            <DescriptionList.ButtonItem
              label="Edit source"
              description="Edit source fields. Source 'vendor and host' cannot be changed."
            >
              <Button
                text="Edit source"
                type={ButtonStyle.DANGER}
                onClick={() => navigate(`/sources/${id}/edit`)}
              />
            </DescriptionList.ButtonItem>
            <DescriptionList.ButtonItem
              label="Delete source"
              description="Once you delete a source, the action cannot be undone."
            >
              <Button
                text="Delete source"
                type={ButtonStyle.DANGER}
                onClick={onDelete}
              />
            </DescriptionList.ButtonItem>
          </DescriptionList>
        </div>
      </div>
    </>
  );
};

export default SourceDetails;
