import axios, {
  ApiRoutes,
  ApiResponse,
  AppError,
  ErrorResponse,
  ID_PLACEHOLDER,
  RequestStatus,
  API_VERSION_HEADER,
  API_VERSION_2023_12_01,
} from "../../axios";

import { WithRedirect } from "..";
import ExistingSource, {
  ExistingSourceTestPayload,
  PreparedSource,
  SourceType,
} from ".";
import { EnumObject } from "@prequel/react";
import { SourceForm } from "./form";

const getSources: () => Promise<ExistingSource[]> = () => {
  return axios
    .get(ApiRoutes.SOURCES, {
      headers: {
        [API_VERSION_HEADER]: API_VERSION_2023_12_01,
      },
    })
    .then((response: ApiResponse<{ sources: ExistingSource[] }>) => {
      const sources: ExistingSource[] = response.data.data.sources.map(
        (s: ExistingSource) => ({
          ...s,
          type: SourceType.ExistingSource,
        })
      );
      return sources;
    })
    .catch((reason: ErrorResponse) => {
      const e = {
        error: {
          message: reason.response?.data?.message || "Failed to fetch sources.",
          suppressGlobalNotification: true,
          statusCode: reason.response?.status,
        },
      };
      throw e;
    });
};

const testNewSource: (s: PreparedSource) => Promise<RequestStatus> = (
  source
) => {
  return axios
    .post(
      ApiRoutes.TEST_SOURCE,
      { source },
      {
        headers: {
          [API_VERSION_HEADER]: API_VERSION_2023_12_01,
        },
      }
    )
    .then((response: ApiResponse<RequestStatus>) => response.data.data)
    .catch((reason: ErrorResponse) => {
      const e: AppError = {
        error: {
          message:
            reason.response?.data?.message || "Source connection test failed.",
          suppressGlobalNotification: true,
          statusCode: reason.response?.status,
        },
      };
      throw e;
    });
};

const testExistingSource: (
  p: ExistingSourceTestPayload
) => Promise<RequestStatus> = ({ sourceId, fields }) => {
  const url = ApiRoutes.TEST_EXISTING_SOURCE.replace(ID_PLACEHOLDER, sourceId);
  return axios
    .post(url, fields ? { source: fields } : undefined, {
      headers: {
        [API_VERSION_HEADER]: API_VERSION_2023_12_01,
      },
    })
    .then((response: ApiResponse<RequestStatus>) => response.data.data)
    .catch((reason: ErrorResponse) => {
      const e: AppError = {
        error: {
          message:
            reason.response?.data?.message || "Source connection test failed.",
          suppressGlobalNotification: true,
          statusCode: reason.response?.status,
        },
      };
      throw e;
    });
};

const postSource: (
  p: WithRedirect<{ source: PreparedSource }>
) => Promise<WithRedirect<{ source: ExistingSource }>> = ({
  source,
  redirect,
}) => {
  return axios
    .post(
      ApiRoutes.SOURCES,
      { source },
      {
        headers: {
          [API_VERSION_HEADER]: API_VERSION_2023_12_01,
        },
      }
    )
    .then((response: ApiResponse<{ source: ExistingSource }>) => ({
      source: response.data.data.source,
      redirect,
    }))
    .catch((reason: ErrorResponse) => {
      const e: AppError = {
        error: {
          message: reason.response?.data?.message || "Source creation failed.",
          suppressGlobalNotification: true,
          statusCode: reason.response?.status,
        },
      };
      throw e;
    });
};

const patchSource: (
  p: WithRedirect<{
    sourceId: ExistingSource["id"];
    source: Partial<PreparedSource>;
  }>
) => Promise<WithRedirect<{ source: ExistingSource }>> = ({
  sourceId,
  source,
  redirect,
}) => {
  return axios
    .patch(
      `${ApiRoutes.SOURCES}/${sourceId}`,
      {
        source: source,
      },
      {
        headers: {
          [API_VERSION_HEADER]: API_VERSION_2023_12_01,
        },
      }
    )
    .then((response: ApiResponse<{ source: ExistingSource }>) => ({
      source: response.data.data.source,
      redirect,
    }))
    .catch((reason: ErrorResponse) => {
      const e: AppError = {
        error: {
          message:
            reason?.response?.data?.message || "Failed to update source.",
          suppressGlobalNotification: false,
          statusCode: reason.response?.status,
        },
      };
      throw e;
    });
};

const deleteSource: (
  p: WithRedirect<{
    sourceId: ExistingSource["id"];
  }>
) => Promise<WithRedirect<{}>> = ({ sourceId, redirect }) => {
  return axios
    .delete(`${ApiRoutes.SOURCES}/${sourceId}`)
    .then(() => ({ redirect }))
    .catch((reason: ErrorResponse) => {
      const e: AppError = {
        error: {
          message:
            reason?.response?.data?.message || "Failed to delete source.",
          suppressGlobalNotification: false,
          statusCode: reason.response?.status,
        },
      };
      throw e;
    });
};

const getSourceForm: (orgID?: string | undefined) => Promise<SourceForm> = (
  orgID
) => {
  return axios
    .get(ApiRoutes.PUBLIC_SOURCE_FORM, {
      params: {
        org_id: orgID,
      },
    })
    .then((response: ApiResponse<SourceForm>) => response.data.data)
    .catch((reason: ErrorResponse) => {
      const e: AppError = {
        error: {
          message:
            reason?.response?.data?.message || "Failed to fetch source form.",
          suppressGlobalNotification: false,
          statusCode: reason.response?.status,
        },
      };
      throw e;
    });
};

const getSourceVendors: () => Promise<EnumObject[]> = () => {
  return axios
    .get(ApiRoutes.PUBLIC_SOURCE_VENDORS)
    .then((response: ApiResponse<EnumObject[]>) => response.data.data)
    .catch((reason: ErrorResponse) => {
      const e: AppError = {
        error: {
          message:
            reason?.response?.data?.message ||
            "Failed to fetch source vendors.",
          suppressGlobalNotification: false,
          statusCode: reason.response?.status,
        },
      };
      throw e;
    });
};

const generateSourcePublicKey: () => Promise<string> = () => {
  return axios
    .post(ApiRoutes.GENERATE_PUBLIC_KEY)
    .then(
      (response: ApiResponse<{ public_key: string }>) =>
        response.data.data.public_key
    )
    .catch((reason: ErrorResponse) => {
      const e: AppError = {
        error: {
          message:
            reason?.response?.data?.message ||
            "Failed to generate public SSH key.",
          suppressGlobalNotification: false,
          statusCode: reason.response?.status,
        },
      };
      throw e;
    });
};

const SourcesService = {
  getSources,
  testNewSource,
  postSource,
  patchSource,
  deleteSource,
  testExistingSource,
  getSourceForm,
  getSourceVendors,
  generateSourcePublicKey,
};
export default SourcesService;
