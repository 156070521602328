import React from "react";
import {
  RadioButtons,
  RadioCardOption,
  RadioCards,
  Toggle,
} from "@prequel-internal/react-components";
import { Destination, FormField } from "@prequel/react";

import VendorLogo from "../../../components/VendorLogo";
import { SourceFormField } from "../../../store/sources/form";
import { Source } from "../../../store/sources";
import { WrapperProps } from "..";

type RadioWrapperProps<
  F extends FormField | SourceFormField,
  C extends Destination | Source
> = WrapperProps<F & { form_element: "radio" }, C>;
const RadioWrapper = <
  F extends FormField | SourceFormField,
  C extends Destination | Source
>({
  field,
  connection,
  setConnectionField,
  disabled,
}: RadioWrapperProps<F, C>) => {
  if (field.type === "boolean") {
    return (
      <div key={field.name}>
        <Toggle
          label={field.label}
          enabled={!!connection[field.name]}
          setEnabled={(e: boolean) =>
            setConnectionField(field.name as keyof C, e)
          }
        />
      </div>
    );
  } else if (
    field.name === "auth_method" ||
    field.name === "bucket_auth_method"
  ) {
    const items: RadioCardOption<string>[] = field.enum.map((e) => ({
      value: e.key.toString(),
      label: e.display,
      enabled: true,
      icon: e.icon_url ? <VendorLogo logo_url={e.icon_url} /> : <></>,
    }));
    const selected = items.find(
      ({ value }) => value === connection[field.name]
    );

    return (
      <RadioCards
        size="md"
        label={field.label}
        options={items}
        selectedOption={selected}
        setSelectedOption={(opt) =>
          setConnectionField(field.name as keyof C, opt.value)
        }
        disabled={disabled}
      />
    );
  } else {
    const items = field.enum.map((e) => ({
      key: e.key.toString(),
      name: e.display,
      content: e.key,
    }));
    const selected = items.find(({ key }) => key === connection[field.name]);

    return (
      <div key={field.name}>
        <RadioButtons
          label={field.label}
          options={items}
          selectedOption={selected}
          setSelectedOption={(selected) =>
            setConnectionField(field.name as keyof C, selected.key)
          }
          disabled={disabled}
        />
      </div>
    );
  }
};

export default RadioWrapper;
